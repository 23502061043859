@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
body {
    font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3 {
    font-family: 'Yantramanav', sans-serif;
}

h4,
h5,
h6 {
    font-family: 'Oswald', sans-serif;
}


/* Slider */

.slick-current .slide-content-container {
    background-color: #064E3B;
    color: #fff;
}

.slick-prev:before,
.slick-next:before {
    color: #064E3B!important;
    font-family: 'FontAwesome'!important;
}

.slick-prev:before {
    content: "\f053"!important;
}

.slick-next:before {
    content: "\f054"!important;
}


/* Tabs */

.react-tabs__tab {
    background-color: #481B8D;
    border-radius: 5px 5px 0 0;
    display: flex!important;
    flex-direction: column;
    width: 32.5%;
}

.react-tabs__tab--selected {
    background-color: #F5F3FF!important;
    border-color: #F5F3FF!important;
    color: #481B8D!important;
}

.about-item-title-container {
    min-height: 56px;
}


/* End of tabs */

@media only screen and (min-width: 1024px) {
    /* Hero Section */
    .hero-column-1 {
        max-width: 522px;
    }
}

@media only screen and (min-width: 768px) {
    .slick-slide:not(.slick-current) .slide-container {
        transform: scale(85%);
        margin-left: 0;
        margin-right: 0;
        cursor: pointer;
    }
    .slick-current {
        position: relative;
        z-index: 1;
    }
    .slick-current .slide-container {
        transform: scale(105%);
        margin-left: -50px;
        margin-right: -50px;
    }
}

@media only screen and (max-width: 1024px) {
    .slick-prev,
    .slick-next {
        z-index: 1;
    }
    .slick-next {
        right: 0!important;
    }
    .slick-prev {
        left: 0!important;
    }
    .slick-next {
        right: 0!important;
    }
}


/* Home Section 3 */

.rr-section-top-half {
    margin-bottom: -30vh;
}

.rr-section-bottom-half {
    min-height: 30vh;
}

.rr-financedeal-img {
    box-shadow: -10px -10px rgb(6 78 59 / 50%);
    border-top-left-radius: 5px;
}

@media only screen and (min-width: 768px) {
    .rr-about-title-text {
        width: max-content;
    }
}